<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col class="col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <h3 class="text-h6 mb-4 rounded">
              はむいぬ.comってどんなサイト？
            </h3>
            <p class="text-body-1">
              はむいぬ.comは、Web上で「オモシロいコンテンツ」「タノシいコンテンツ」「ベンリなコンテンツ」を展開している<a href="#about_hine">Hi.N.E</a>によって運営されているプチサイトです。
            </p>
            <p class="text-body-1">
              まだまだ始まったばっかりなのでコンテンツはありませんが、そのうち増えていく予定です。
            </p>
            <h3 id="about_hine" class="text-h6 mb-4 rounded">
              Hi.N.Eって何？ 由来は？
            </h3>
            <p>
              <v-img src="@/assets/img/hine.png" class="mx-auto" width="225" alt="Hi.N.E ロゴ"></v-img>
            </p>
            <p class="text-body-1">
              Hi.N.Eは、ゲームとインターネットが大好きな<a href="https://twitter.com/#!/aki_hamuinu" target="_blank">はむっち（Twitterのページへ）</a>がWebサイトを作るにあたって名付けた"屋号"のようなものです。<br />
              ちなみに、"Hi.N.E"は"<u>H</u>amu<u>i</u>nu <u>N</u>etwork <u>E</u>ntertainment"の略だったりします。
            </p>
            <p class="text-body-1">
              元々2005年～2006年頃に、自分が初めて練習（という名の自己満）で作ったサイトの名前が"ハムスターと犬"というタイトルで、"hamuinu.com"というドメインはそのサイト名に由来しています。<br />
              "ハムスターと犬"という名前をつけるにあたっては、元々ハムスターが大好きだったのと、さらにその頃ちょうど流行っていたニンテンドーDSの"nintendogs（ニンテンドッグス）"という子犬を飼うゲームの影響が強かったりもしました。<br />
              "はむいぬ"なんて名前ですが、自分が好きなのは"ねむネコ"、"こざらしさん"、"コウペンちゃん"だったりします。<br />
              リアルペットでは"ねこ"と"ハムスター"が好きです。犬関係ないですね。
            </p>
            <p class="text-body-1">
              ゲームを作ったりするときなどは、たまに"はむっち＠ＡＨＤ"の名で活動していることもありました。<br />
              AHDも屋号のようなもので、"A.K. Hamsters and Dogs"（"A.K."は本名のイニシャル）の略だったりします。
            </p>
            <h3 class="text-h6 mb-4 rounded">
              プライバシーポリシー
            </h3>
            <p class="text-body-1">
              <router-link to="/privacy_policy">プライバシーポリシー</router-link>のページをご参照ください。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default {
    name: 'About',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "About"
      ]
    }),
  }
</script>
