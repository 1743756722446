<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col class="col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <h3 class="text-h6 mb-4 rounded">
              運営コンテンツについて
            </h3>
            <p class="text-body-1">
              いくつかのAndroidアプリを公開したり、いろんなWebサービスを展開中です。
            </p>
            <v-card class="mx-auto mb-4 text-body-1" outlined>
              <h4 class="text-subtitle-1">note はじめました！</h4>
              <v-row class="px-3">
                <v-col class="col-12 col-md-6 col-lg-5 pr-lg-0 pb-0">
                  <a href="https://note.com/hamuinu" target="_blank">
                    <v-img v-ripple="{ class: 'note-color' }" src="@/assets/note/logo_tagline.png" class="mx-auto" width="200" style="border: solid 2px #41C9B4; border-radius: 8px;" alt="note ロゴ"></v-img>
                  </a>
                </v-col>
                <v-col class="col-12 col-md-6 col-lg-7 pl-lg-0 pb-0">
                  あきにゃん（ hamuinu ）の名前で不定期に記事を書いているので、興味のある方はぜひ見ていってください。
                </v-col>
                <v-col class="col-12">
                  <v-btn block dark depressed color="#41C9B4" href="https://note.com/hamuinu" target="_blank">クリエイターページ へ</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="mx-auto mb-4 text-body-1" outlined>
              <h4 class="text-subtitle-1">スポンサード リンク</h4>
              <!-- はむいぬ.com コンテンツ -->
              <adsense
                ad-client="ca-pub-7162784326630429"
                ad-slot="8118945476"
                ad-style="display: block"
                ad-format="auto"
                data-full-width-responsive="true">
              </adsense>
            </v-card>
            <template v-for="item in contents">
              <h4 :key="item.title + '-header'" class="text-subtitle-1 mb-4">
                <v-icon :color="getCategoryColor( item.category ) + ' accent-4'">
                  {{ getCategoryIcon( item.category ) }}
                </v-icon> {{ decodeEntities( item.title ) }}{{ getCategorySuffix( item.category ) }}
              </h4>
              <div :key="item.title + '-body'" class="d-flex">
                <v-row>
                  <v-col class="col-12 col-md-6 col-lg-5 pr-md-0 py-0">
                    <p>
                      <a :href="item.uri" target="_blank">
                        <v-img :src="getAttachedImagePath( item )" class="mx-auto" width="300" :alt="decodeEntities( item.title )"></v-img>
                      </a>
                    </p>
                  </v-col>
                  <v-col class="col-12 col-md-6 col-lg-7 pl-md-0 py-0">
                    <p v-html="decodeEntities( item.description )" class="text-body-1">
                    </p>
                    <p class="text-body-1 mb-6">
                      <v-btn block dark depressed :color="getCategoryColor( item.category ) + ' accent-4'" :href="item.uri" target="_blank">{{ getCategoryButtonText( item.category ) }}</v-btn>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<style>
  .note-color{
    color: #41C9B4;
    opacity: 0.5;
  }
</style>

<script>
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';
  import { textConvertMixins } from '@/mixins/TextConvert';
  import { contentsMixins } from '@/mixins/Contents';

  import contents from '@/assets/data/contents.json'

  export default {
    name: 'Contents',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    mixins: [
      textConvertMixins,
      contentsMixins
    ],
    data: () => ({
      breadCrumbs: [
        "Home",
        "Contents"
      ],
      contents: contents.data
    }),
  }
</script>
