<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col class="col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <h3 class="text-h6 mb-4 rounded">
              メモプロを使って、ラクラク管理しませんか？
            </h3>
            <p class="text-body-1 mb-12 px-0">
              <v-img src="/img/lp_memoproject_keyvisual.png" class="mx-auto" width="100%" alt="メモプロを使って、ラクラク管理しませんか？"></v-img>
            </p>
            <p class="text-body-1">
              仕事や家のこと、プライベートで忙しい毎日。 <br />
              あなたは、こういうことって経験ありませんか？
            </p>
            <ul class="text-body-1 thinking-bubbles mb-12">
              <li>どのメモから先に片付ければいいの？</li>
              <li>あの大事なメモはどこいったの？</li>
              <li>メモしておいたのに忘れちゃった！</li>
            </ul>
            <p class="text-body-1">
              リマインドや優先度、進捗度を毎回メモに追記して管理するのは大変ですよね。
            </p>
            <p class="text-body-1">
              ただでさえ忙しい毎日、もう少し楽したいなと思うこともありますよね。
            </p>
            <p class="text-body-1">
              <v-img src="/img/lp_memoproject_illust_toobusy.png" class="mx-auto" width="100%" alt="忙しい毎日のイラスト"></v-img>
            </p>
            <p class="text-body-1">
              もし、リマインドや優先度、進捗度の機能が付いたメモアプリがあれば、嬉しくないですか？
            </p>
            <p class="text-body-1 mb-12">
              メモプロは、まさにそんなメモアプリです。
            </p>
            <p class="text-body-1">
              <v-img src="/img/lp_memoproject_illust_memoproject.png" class="mx-auto" width="100%" alt="メモプロのイラスト"></v-img>
            </p>
            <p class="text-body-1">
              メモプロは、単にメモをとるだけではありません。
            </p>
            <p class="text-body-1">
              他にも多くの機能があなたをサポートしてくれます。
            </p>
            <v-card class="rounded-lg mx-3 mb-4 text-body-1" outlined>
              <strong><v-img src="/img/lp_memoproject_func_reminder.png" class="rounded-lg rounded-b-0 mx-auto mb-2" width="100%" alt="リマインダー機能"></v-img></strong>
              <p class="text-body-1 mx-4 mb-4 pa-0">
                通知してほしい日時をメモと一緒に設定しておくと、その日時になったらアプリがメモと一緒に通知してくれます。
              </p>
            </v-card>
            <v-card class="rounded-lg mx-3 mb-4 text-body-1" outlined>
              <strong><v-img src="/img/lp_memoproject_func_favorite.png" class="rounded-lg rounded-b-0 mx-auto mb-2" width="100%" alt="お気に入り機能"></v-img></strong>
              <p class="text-body-1 mx-4 mb-4 pa-0">
                たくさんのメモの中から大事なメモのみを仕分けでき、仕分けした状態で閲覧することもできます。
              </p>
            </v-card>
            <v-card class="rounded-lg mx-3 mb-4 text-body-1" outlined>
              <strong><v-img src="/img/lp_memoproject_func_timeline.png" class="rounded-lg rounded-b-0 mx-auto mb-2" width="100%" alt="タイムライン機能"></v-img></strong>
              <p class="text-body-1 mx-4 mb-4 pa-0">
                リマインドしたメモをタイムライン形式で閲覧でき、過去のリマインドは表示する／しないを切り替えられます。
              </p>
            </v-card>
            <v-card class="rounded-lg mx-3 mb-4 text-body-1" outlined>
              <strong><v-img src="/img/lp_memoproject_func_taskstatus.png" class="rounded-lg rounded-b-0 mx-auto mb-2" width="100%" alt="タスクステータス機能"></v-img></strong>
              <p class="text-body-1 mx-4 mb-4 pa-0">
                メモをタスク付箋のように扱うことができ、進捗度合いを一緒に設定しておくとダッシュボードへ反映されます。
              </p>
            </v-card>
            <p class="text-body-1">
              メモプロを使えば、メモを忘れないようにリマインドできたり、大事なメモはお気に入りとして集約できたり、タイムラインでリマインドを見える化できたり、タスクメモの進捗度合いも明確化できたり、一石四鳥ですね！
            </p>
            <p class="text-body-1 mb-12">
              少しでも興味がありましたら、ぜひ一度使ってみてください。
            </p>
            <v-row class="mb-12">
              <v-col class="mx-3 py-0">
                <v-btn block dark depressed color="#3fb553" href="https://play.google.com/store/apps/details?id=com.hamuinu.memoproject" target="_blank">アプリストアからダウンロード</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default {
    name: 'MemoProject',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "LPMemoProject"
      ]
    }),
  }
</script>
