<template>
  <v-col class="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2">
    <v-card outlined class="d-none d-md-block mb-4">
      <h4 class="text-subtitle-1 pt-1 pl-3 pr-3 pb-1 mb-3">
        スマホ用サイト
      </h4>
      <p class="text-subtitle-1 text-center mb-3">
        お手持ちのスマホからも閲覧できます！
      </p>
      <p>
        <v-img src="@/assets/img/qrcode.png" class="mx-auto" width="150" alt="スマホサイトQRコード"></v-img>
      </p>
      <p class="text-subtitle-2 mb-3 red--text">
        ※お手持ちのスマホにQRコード読み取りアプリがない場合は、事前に<a href="https://itunes.apple.com/jp/genre/ios-yutiriti/id6002" target="_blank">App Store(iPhone/iPad/iPod Touch)</a>、<a href="https://play.google.com/store/search?q=QR%E3%82%B3%E3%83%BC%E3%83%89&hl=ja" target="_blank">Google Play(Android)</a>または<a href="https://www.microsoft.com/ja-jp/store/most-popular/apps/pc?target=apps..utilities%20%26%20tools" target="_blank">Microsoft ストア(Windows8/Windows10)</a>からアプリをダウンロード後、インストールしておいてください。
      </p>
      <p class="text-subtitle-2 mb-3 red--text">
        Androidをご利用の方は、当方制作の<a href="https://play.google.com/store/apps/details?id=com.hamuinu.qrmaker">QRメーカー</a>のQRコード読み取り機能をご利用ください。アプリ起動後、画面右上のアイコンをタップで読み取り機能を使用できます。
      </p>
    </v-card>
    <v-card outlined class="mb-4">
      <h4 class="text-subtitle-1 pt-1 pl-3 pr-3 pb-1">
        メモプロでラクラク管理！
      </h4>
      <v-row class="mt-0">
        <v-col class="py-0">
          <a href="/lp/memo-project">
            <v-img src="/img/lp_memoproject_intro_thumb.png" class="mx-auto mb-3" width="100%" alt="メモプロを使って、ラクラク管理しませんか？"></v-img>
          </a>
          <p class="text-subtitle-1 mb-3">
            メモプロを使って、ラクラク管理しませんか？
          </p>
        </v-col>
        <v-col class="mx-3 py-0">
          <v-btn block dark depressed color="#3fb553" class="mb-4" href="/lp/memo-project">ラクラク管理の詳細を見る</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mb-4">
      <h4 class="text-subtitle-1 pt-1 pl-3 pr-3 pb-1 mb-3">
        不思議な窓
      </h4>
      <p class="text-subtitle-1 mb-3">
        ページのすみにひっそりと置かれている、ブログパーツのような「不思議な窓」。
      </p>
      <div class="d-flex justify-center mb-3">
        <canvas id="canvas" class="rounded-lg strange-window"></canvas>
      </div>
      <p class="text-subtitle-2 mb-3 red--text">
        ※<strong>不思議な窓</strong>は、Microsoft EdgeやGoogle Chrome、FireFox、Safari等の最新のウェブブラウザーでご覧になることを推奨します。
      </p>
    </v-card>
    <v-card outlined class="mb-4">
      <h4 class="text-subtitle-1 pt-1 pl-3 pr-3 pb-1">
        スポンサード リンク
      </h4>
      <!-- はむいぬ.com サイドバー -->
      <adsense
        class="text-subtitle-1"
        ad-client="ca-pub-7162784326630429"
        ad-slot="7755342768"
        ad-style="display: block"
        ad-format="auto"
        data-full-width-responsive="true">
      </adsense>
    </v-card>
  </v-col>
</template>

<script>
  import { draw } from '@/strange-window.js';

  export default {
    name: 'SideBar',
    props: [
      'items',
    ],
    data: () => ({
    }),
    mounted: function(){
      draw()
    },
  }
</script>
