<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col class="col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <h3 class="text-h6 mb-4 rounded">
              ページが見つかりません
            </h3>
            <p class="text-body-1">
              お探しのページは見つかりませんでした。
            </p>
            <p class="text-body-1">
              <router-link to="/">トップページ</router-link>、または<router-link to="/sitemap">サイトマップ</router-link>から目的のページを再度お探しください。<br />
              それでも見つからない場合は、<a href="mailto:***@***.***">こちらのメールアドレス</a>から管理者までお問い合わせください。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default {
    name: 'NotFound',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "NotFound"
      ]
    }),
  }
</script>
