<template>
  <v-col class="col-12">
    <v-breadcrumbs class="px-3 pt-2 pb-0" :items="getBreadCrumbs( $router.options.routes, items )">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-col>
</template>

<script>
  import { globalMixins } from '@/mixins';

  export default {
    name: 'BreadCrumbs',
    mixins: [
      globalMixins
    ],
    props: [
      'items',
    ],
  }
</script>
