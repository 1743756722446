import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contents from '../views/Contents.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import MemoProject from '../views/lp/MemoProject.vue'
import NotFound from '../views/NotFound.vue'
import { setTitle, setDescription, setKeywords } from '@/mixins';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: "このサイトについて"
    }
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: "プライバシーポリシー"
    }
  },
  {
    path: '/contents',
    name: 'Contents',
    component: Contents,
    meta: {
      title: "運営コンテンツについて"
    }
  },
  {
    path: '/lp',
    redirect: '/',
  },
  {
    path: '/lp/memo-project',
    name: 'LPMemoProject',
    component: MemoProject,
    meta: {
      title: 'メモプロを使って、ラクラク管理しませんか？'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: "ページが見つかりません"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior( to, from, savedPosition ){ // eslint-disable-line no-unused-vars
    return { x: 0, y: 0 }
  }
})

router.beforeEach( ( to, from, next ) => {
  setTitle( to.meta.title )
  setDescription( to.meta.description )
  setKeywords( to.meta.keywords )
  next()
})

export default router
