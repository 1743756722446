import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import adsense from './plugins/adsense';
import moment from './plugins/moment';
import { globalMixins } from '@/mixins';

Vue.config.productionTip = false
Vue.mixin( globalMixins )

new Vue({
  router,
  store,
  vuetify,
  adsense,
  moment,
  render: h => h(App)
}).$mount('#app')

import "@/styles/style.less";
